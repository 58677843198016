//Import Icon css
@import './icons.scss';

//light mode
@import './bootstrap.scss';
@import './app.scss';

// rtl mode

// @import "./custom/rtl/bootstrap-rtl";
// @import "./custom/rtl/components-rtl";
// @import "./custom/rtl/float-rtl";
// @import "./custom/rtl/general-rtl.scss";
// @import "./custom/rtl/pages-rtl";
// @import "./custom/rtl/spacing-rtl";
// @import "./custom/rtl/text-rtl";

html {
	height: -webkit-fill-available;
}

body {
	height: -webkit-fill-available;
}

.text-divider {
	--text-divider-gap: 1rem;
	display: flex;
	align-items: center;
	font-size: 0.75rem;
	text-transform: uppercase;
	letter-spacing: 0.1em;
}

.text-divider::before,
.text-divider::after {
	content: '';
	height: 1px;
	background-color: silver;
	flex-grow: 1;
}

.text-divider::before {
	margin-right: var(--text-divider-gap);
}

.text-divider::after {
	margin-left: var(--text-divider-gap);
}

.of {
	overflow: scroll;
}
.sp {
	justify-content: space-between;
}

.pointer {
	cursor: pointer;
}

.stickyP {
	// stick after 1000px

	@media screen and (min-width: 1000px) {
		position: sticky;
		top: 0;
	}
}

.backDash {
	transition: all 0.5s;
	border-radius: 1rem;
	padding: 1rem 2rem;
	margin-top: 1rem;
}

// .backDash:hover {
// 	// background: linear-gradient(to right, #f14d62, #eb436f) !important;
// 	border-radius: 1rem;
// 	color: white !important;
// }

.headertext {
	font-size: 1.25rem;
	@media screen and (max-width: 1200px) {
		font-size: 1rem;
	}
}

.headertitle {
	font-size: 1.5rem;
	@media screen and (max-width: 1200px) {
		font-size: 1.15rem;
	}
}
.cflexc {
	@media screen and (max-width: 400px) {
		display: flex;
		flex-direction: column;
	}
}

.cheightPhonecodes {
	max-height: 200px;
	overflow: scroll;
	overflow-x: hidden; /* Hide horizontal scrollbar */
}

.hidewhilemobile {
	@media screen and (max-width: 1000px) {
		display: none;
	}
}

.hidescroll {
	overflow-y: hidden; /* Hide vertical scrollbar */
	overflow-x: hidden; /* Hide horizontal scrollbar */
}
.cheight {
	height: -webkit-fill-available;
}

.cvh {
	@media screen and (min-width: 1000px) {
		height: 100vh;
	}
}

.alertCustom {
	margin-left: 95px;
	max-width: 350px;
}

.cbcolor {
	color: var(--bs-gray-700);
}

.fixedp {
	position: fixed;
	bottom: 0;
}

.wdncolor {
	background: linear-gradient(to right, #f14d62, #eb436f) !important;
}

.wdnborder {
	border-color: #f14d62 !important;
}

.wdnbg1 {
	background-color: #f14d62 !important;
}

.wdnbg2 {
	background-color: #eb436f !important;
}

.wdntext1 {
	color: #f14d62 !important;
}

.wdntext2 {
	color: #eb436f !important;
}

.onlinew {
	width: 60px;
}

.onlineuseravatar {
	object-fit: cover !important;
	height: 100%;
	width: 100%;
	border-radius: 50%;
}
.avatarBW {
	border: 1px solid white;
}

.avatarBB {
	border: 1px solid black;
}

.fab {
	color: #f14d62;
	border-radius: 50%;
	padding: 10px;
	margin: 10px;
	position: fixed;
	bottom: 100px;
	right: 200px;
	border: 1px sloid black;
}

.scrollflow {
	// making scrollable but now showing the scroll bar
	overflow-y: hidden;
	overflow-x: scroll;
	cursor: grab;
}

.scrollflow::-webkit-scrollbar {
	display: none; /* for Chrome, Safari, and Opera */
}

.scrollflow::-webkit-scrollbar-track {
	display: none; /* for Chrome, Safari, and Opera */
}

.scrollflow::-webkit-scrollbar-thumb {
	display: none; /* for Chrome, Safari, and Opera */
}

.scrollflow::-webkit-scrollbar-button {
	display: none; /* for Chrome, Safari, and Opera */
}

.scrollflow::-webkit-scrollbar-corner {
	display: none; /* for Chrome, Safari, and Opera */
}

.nav-item {
	cursor: pointer;
}

.dropdown {
	cursor: pointer;
}

.slick-slide {
	margin-left: 8px;
	margin-right: 8px;
}

.slick-arrow {
	display: none;
}

.emoji-mart {
	border: none !important;
}

.emoji-mart-preview {
	display: none !important;
}

.emoji-mart-bar {
	border: none !important;
}

.alice-carousel__stage-item {
	margin-left: 8px;
	margin-right: 8px !important;
	width: 71px !important;
}

.input-file {
	label {
		margin-bottom: 0;
	}
	input[type='file'] {
		display: none;
	}
}

.emoji-mart-light {
	background-color: $card-bg !important;
}

.emoji-mart-search {
	input {
		background-color: $card-bg !important;
		border-color: $card-border-color !important;
	}
}

.emoji-mart-category-label {
	span {
		background-color: $card-bg !important;
		color: $gray-700;
	}
}

.emoji-mart-category {
	.emoji-mart-emoji {
		&:hover {
			background-color: $gray-300 !important;
		}

		&::before {
			background-color: $gray-300 !important;
			border-radius: 100% !important;
		}
	}
}
.w-70 {
	width: 73% !important;
}

.ctext-wrap-content {
	animation: flyIn 0.6s ease-in-out;
}

.blank-div {
	width: 36px;
	height: 36px;
}

.profile-user-dropdown {
	.dropdown-item {
		&.active {
			color: $white;
			text-decoration: none;
			background-color: $primary;
		}
	}
}
.dot_remove {
	.alice-carousel__dots,
	.alice-carousel__prev-btn,
	.alice-carousel__next-btn {
		display: none !important;
	}
}
.close {
	background: transparent;
	border: 0;
	font-size: 30px;
	margin: 10px;
	position: absolute;
	top: 0;
	right: 0;
	opacity: 0.5;
	width: 1em;
	height: 1em;
	z-index: 2;
}

//emoji dropdown menu
.emoji-dropdown {
	.dropdown-menu {
		animation: none;
	}
}

.input-group-text {
	background-color: var(--#{$variable-prefix}gray-200);
}

.bg-soft-light {
	background-color: var(--#{$variable-prefix}light-rgb) !important;
}

[data-layout-mode='dark'] {
	.auth-logo {
		.logo-dark {
			display: none;
		}
		.logo-light {
			display: block;
		}
	}
	.emoji-categories {
		background-color: $white;
	}
	.emoji-picker-react {
		background-color: var(--#{$variable-prefix}gray-200) !important;
		border-color: var(--#{$variable-prefix}gray-200) !important;
		box-shadow: 0 5px 10px var(--#{$variable-prefix}gray-200) !important;
	}
	.emoji-search {
		background-color: var(--#{$variable-prefix}input-bg) !important;
		border-color: var(--#{$variable-prefix}input-bg) !important;
		color: $input-color;
	}
	.emoji-picker-react .emoji-group:before {
		background-color: var(--#{$variable-prefix}gray-200) !important;
	}
}

.profile-user-dropdown {
	&.dropup {
		.dropdown-menu {
			bottom: 51px !important;
			// left: -100px !important;
			top: auto !important;
			right: 20px !important;
			&.show {
				top: auto !important;
			}
			// add media query if px belows 750px
			@media (max-width: 750px) {
				left: -50px !important;
				right: 0 !important;
			}
			@media (max-width: 500px) {
				left: -100px !important;
				right: 0 !important;
			}
		}
	}
}

.chatinput.shake {
	/* Start the shake animation and make the animation last for 0.5 seconds */
	animation: shake 0.5s;

	/* When the animation is finished, start again */
	animation-iteration-count: forwards;
}

@keyframes shake {
	0% {
		transform: translate(1px, 1px) rotate(0deg);
	}
	10% {
		transform: translate(-1px, -2px) rotate(-1deg);
	}
	20% {
		transform: translate(-3px, 0px) rotate(1deg);
	}
	30% {
		transform: translate(3px, 2px) rotate(0deg);
	}
	40% {
		transform: translate(1px, -1px) rotate(1deg);
	}
	50% {
		transform: translate(-1px, 2px) rotate(-1deg);
	}
	60% {
		transform: translate(-3px, 1px) rotate(0deg);
	}
	70% {
		transform: translate(3px, 1px) rotate(-1deg);
	}
	80% {
		transform: translate(-1px, -1px) rotate(1deg);
	}
	90% {
		transform: translate(1px, 2px) rotate(0deg);
	}
	100% {
		transform: translate(1px, -2px) rotate(-1deg);
	}
}

.charError {
	top: 50%;
	transform: translate(0%, -50%);
	right: 10px;
	font-weight: 600;
}

.charError {
	display: block;
	// text not selectable
	user-select: none;
}

.chatTextInput {
	padding-right: 70px;
}
